.xxbtn-custom-google-signin {
  width: 191px;
  height: 46px;
  padding: 0;
  border: 0 none;
  border-radius: 2px;
  background: transparent url(../../../static/img/btn_google_signin_light_normal_web.png) no-repeat 0 0 / contain;

  &[disabled] {
    background: transparent url(../../../static/img/btn_google_signin_light_disabled_web.png) no-repeat 0 0 / contain;
  }
  &:hover, &:focus {
    background: transparent url(../../../static/img/btn_google_signin_light_focus_web.png) no-repeat 0 0 / contain;
    outline: 0 none;
  }
  &:active {
    background: transparent url(../../../static/img/btn_google_signin_light_pressed_web.png) no-repeat 0 0 / contain;
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
    & {
      background: transparent url(../../../static/img/btn_google_signin_light_normal_web@2x.png) no-repeat 0 0 / contain;
    }
    &[disabled] {
      background: transparent url(../../../static/img/btn_google_signin_light_disabled_web@2x.png) no-repeat 0 0 / contain;
    }
    &:hover, &:focus {
      background: transparent url(../../../static/img/btn_google_signin_light_focus_web@2x.png) no-repeat 0 0 / contain;
    }
    &:active {
      background: transparent url(../../../static/img/btn_google_signin_light_pressed_web@2x.png) no-repeat 0 0 / contain;
    }
  }
}

.modal-no-border {
  &>.modal-content{
    border: 0 none;

    &>.modal-header,
    &>.modal-body,
    &>.modal-footer{
      border: 0 none;
    }
  }
}

.jumbotron>p.lead {
  margin-bottom: .5rem;
}
