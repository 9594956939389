@mixin animated-fade($sel, $enter-timeout: 500ms, $leave-timeout: 300ms, $appear-timeout: 500ms) {
  #{$sel} {
    direction: ltr;
  }
  #{$sel}-enter {
    opacity: 0.01;
  }
  #{$sel}-enter-active {
    opacity: 1;
    transition: opacity $enter-timeout ease-in;
  }

  #{$sel}-exit {
    opacity: 1;
  }
  #{$sel}-exit-active {
    opacity: 0.01;
    transition: opacity $leave-timeout ease-in;
  }

  #{$sel}-appear {
    opacity: 0.01;
  }
  #{$sel}-appear-active {
    opacity: 1;
    transition: opacity $appear-timeout ease-in;
  }
}
