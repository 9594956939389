@import "../../../styles/mixins";

.Alerts--wrap {
  position: fixed;
  top: 0;
  right: 1rem;
  z-index: 10000;
  width: 20rem;

  & > .alert {
    width: auto;
    min-width: 18rem;
    right: 0;
    margin-left: auto;
    margin-right: 0;
    padding-right: 3rem;
  }
}

@include animated-fade(".Alerts-fade")

.toast.toast-mod {
  background-color: hsla(0, 0%, 100%, .93);
}
